import React from "react"
import { graphql } from 'gatsby'
import Head from "../components/Head"
import '../styles/index.css'

const NewsPage = () => (
  <Head title="Home" />
)

export const query = graphql`
query {
  prismic {
    allFooters {
      edges {
        node {
          instagram
          linkedin
          newsletter
          copyright
        }
      }
    }
    allNewss(sortBy: date_DESC) {
      edges {
        node {
          title
          date
          body {
            __typename
            ... on PRISMIC_NewsBodyText {
              type
              label
              primary {
                text
              }
            }
            ... on PRISMIC_NewsBodyImage {
              type
              label
              primary {
                image
              }
            }
          }
        }
      }
    }
  }
}
`

export default NewsPage
